<template>
    <div class="page-header">
        <h1 class="typography typography--h1">{{ title }}</h1>
        <ButtonComponent v-if="buttonCaption" @click="$emit('pageHeaderButtonClick')" class="page-header__button" :caption="buttonCaption" />
    </div>
</template>

<script>
    import ButtonComponent from "@/components/ui/ButtonComponent"
    export default {
        props: {
            title: {
                required: true,
                type: String
            },
            buttonCaption: {
                required: false,
                type: String
            }
        },
        components: {
            ButtonComponent
        }
    }
</script>

<style lang="scss" scoped>
.typography{
    color: #152847;
}
    .page-header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 20px;

        &__button {
            height: 45px;
            padding: 13px 2px;
            width: 195px;
            letter-spacing: 1px;

            @media screen and (max-width: $sm) {
                margin-top: 10px;
            }
        }

        @media screen and (max-width: $sm) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
</style>
