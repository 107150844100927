<template>
    <nav v-if="!isHome" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb__item">
            <router-link class="typography breadcrumb__link" :to="linkHome">{{ $t('home') }}</router-link>
        </li>
        <li class="breadcrumb__item" v-for="(bread, index) in links" :key="index">
            <span class="material-icons breadcrumb__icon">keyboard_double_arrow_right</span>
            <router-link :to="bread.to" class="typography breadcrumb__link" active-class="breadcrumb__link--active" exact>{{ bread.label }}</router-link>
        </li>
      </ol>
    </nav>
</template>

<script>
export default {
    props: {
        links: {
            required: false,
        }
    },
    data(){
      return{
          linkHome: null,
      }
    },
    computed: {
        isHome() {
            return this.$route.name == 'Home'
        }
    },
    created() {
        if (this.role.getRole() === 1){
            this.linkHome = '/admin/users'
        } else if (this.role.getRole() === 2){
            this.linkHome = '/statistics'
        } else if (this.role.getRole() === 3){
            this.linkHome = '/home/distributors'
        } else if (this.role.getRole() === 4){
            this.linkHome = '/home/network'
        } else if (this.role.getRole() === 5){
            this.linkHome = '/home/cinema'
        }
    }
}
</script>

<style lang="scss">
    .breadcrumb {
        margin: 3px 0;
        margin-top: 8px;

        &__item {
            display: flex;
            align-items: flex-end;
        }

        &__link {
            color: $color_lightpink;
            font-size: 12px;
            font-weight: normal;

            &:hover {
                color: $color_lightpink;
            }

            &--active {
                color: $color_black;
                cursor: initial;

                &:hover {
                    color: $color_black;
                }
            }
        }

        &__icon {
            font-size: 11px;
            margin: 0 2px;
            position: relative;
            bottom: 1px;
        }
        & .breadcrumb__item:last-child a{
            color: #141213;
            cursor: default;
        }
    }
</style>
