<template>
    <div :key="componentKey">
        <div class="container head-container">
            <Breads :links="breadLinks"/>
            <PageHeader :title="$t('Reports')"/>
        </div>
        <div class="container reports-container">
            <div class="head">
                    <div class="head-select-container">
                        <SelectComponent
                            class="select-component main-select"
                            v-model="selectSearchBy"
                            :value="selectSearchBy"
                            :options="reportOptions"
                            :showLabel="true"
                            :placeholder="$t('Reports')"
                            :error="errors.model"
                            :clearable="true"
                        />
                        <SelectComponent
                            class="select-filter checked-select"
                            v-model="mainFilterSelect"
                            :value="mainFilterSelect"
                            :options="mainList"
                            :error="errors.distributors || errors.cinemas"
                            :clearable="true"
                        />
                    </div>
                    <div class="btn-generate-container">
                        <button class="btn-generate" @click="submitForm" type="button">{{$t('Generate a report')}}</button>
                    </div>
            </div>
            <div class="date-container">
                <div class="date-row">
                    <DatePickerComponent
                        class="date-picker"
                        name="date"
                        v-model="reportData.startDateSale"
                        :showLabel='true'
                        :placeholder="$t('Start date of sale')"
                        @input="clearButtonDate('sale')"
                        :error="errors.date_from"
                    />
                    <DatePickerComponent
                        class="date-picker"
                        name="date"
                        v-model="reportData.finishDateSale"
                        :showLabel='true'
                        :placeholder="$t('End date of sale')"
                        @input="clearButtonDate('sale')"
                        :error="errors.date_to"
                    />
                    <div class="btn-date-container">
                        <button
                            @click="sortDateSale('yesterday')"
                            :class="{active : sortBySale.time==='yesterday'}"
                            type="button" class="btn-filter">
                            {{$t('Yesterday')}}
                        </button>
                        <button
                            @click="sortDateSale('today')"
                            :class="{active : sortBySale.time==='today'}"
                            type="button" class="btn-filter">
                            {{$t('Today')}}
                        </button>
                        <button
                            @click="sortDateSale('month')"
                            :class="{active : sortBySale.time==='month'}"
                            type="button"
                            class="btn-filter">
                            {{$t('This month')}}
                        </button>
                        <button
                            @click="sortDateSale('previousMonth')"
                            :class="{active : sortBySale.time==='previousMonth'}"
                            type="button"
                            class="btn-filter">
                            {{$t('Previous')}}
                        </button>
                    </div>
                </div>
                <div class="date-row">
                    <DatePickerComponent
                        class="date-picker"
                        name="date"
                        v-model="reportData.startDateSession"
                        :showLabel='true'
                        :placeholder="$t('Session start date')"
                        @input="clearButtonDate('session')"
                        :error="errors.session_from"
                    />
                    <DatePickerComponent
                        class="date-picker"
                        name="date"
                        v-model="reportData.finishDateSession"
                        :showLabel='true'
                        :placeholder="$t('Session end date')"
                        @input="clearButtonDate('session')"
                        :error="errors.session_to"
                    />
                    <div class="btn-date-container">
                        <button
                            @click="sortDateSession('yesterday')"
                            :class="{active : sortBySession.time==='yesterday'}"
                            type="button"
                            class="btn-filter">
                            {{$t('Yesterday')}}
                        </button>
                        <button
                            @click="sortDateSession('today')"
                            :class="{active : sortBySession.time==='today'}"
                            type="button"
                            class="btn-filter">
                            {{$t('Today')}}
                        </button>
                        <button
                            @click="sortDateSession('month')"
                            :class="{active : sortBySession.time==='month'}" type="button"
                            class="btn-filter">
                            {{$t('This month')}}
                        </button>
                        <button
                            @click="sortDateSession('previousMonth')"
                            :class="{active : sortBySession.time==='previousMonth'}" type="button"
                            class="btn-filter">
                            {{$t('Previous')}}
                        </button>
                        <button
                            @click="sortDateSession('nextMonth')"
                            :class="{active : sortBySession.time==='nextMonth'}" type="button"
                            class="btn-filter">
                            {{$t('Next')}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="multiselect-container border-bottom">
<!--                <MultiselectComponent-->
<!--                    v-if="selectSearchBy != 1"-->
<!--                    :value="distributorMultiselect"-->
<!--                    @change="multiselectChangeDistributors"-->
<!--                    :options="distributorsList"-->
<!--                    :show-label="true"-->
<!--                    :placeholder="null"-->
<!--                    :label="$t('Distributors')"-->
<!--                    name="distributors"-->
<!--                    class="mt-4"-->
<!--                    :multiselectClass="'multiselect&#45;&#45;mini'"-->
<!--                    :error="errors.distributors"-->
<!--                />-->
                <SelectComponent
                    class="select-component mt-4"
                    v-model="distributorMultiselect"
                    :value="distributorMultiselect"
                    @input=multiselectChangeDistributors
                    v-if="selectSearchBy != 1"
                    :options="distributorsList"
                    :showLabel="true"
                    :placeholder="$t('Distributors')"
                    :error="errors.distributors"
                    :clearable="true"

                />
                <MultiselectComponent
                    v-if="selectSearchBy === 3"
                    :value="filmMultiselect"
                    :options="filmsList"
                    @change="multiselectChangeMovie"
                    :show-label="true"
                    :placeholder="null"
                    :label="$t('Movies')"
                    name="movie"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                    :error="errors.movies"
                />
                <SelectComponent
                    v-if="selectSearchBy != 3"
                    class="select-component mt-4"
                    v-model="filmMultiselect"
                    :value="filmMultiselect"
                    @input=multiselectChangeMovie
                    :options="filmsList"
                    :showLabel="true"
                    :placeholder="$t('Movie')"
                    :error="errors.movies"
                    :clearable="true"
                />
            </div>
            <div class="multiselect-container">
                <MultiselectComponent
                    v-if="selectSearchBy != 2 && selectSearchBy != 3"
                    @change="multiselectChangeCinema"
                    :value="cinemaMultiselect"
                    :options="cinemaList"
                    :show-label="true"
                    :placeholder="null"
                    :label="$t('Cinemas')"
                    name="distributors"
                    class="mt-4"
                    :multiselectClass="'multiselect--mini'"
                    :error="errors.cinemas"
                />
<!--                <SelectComponent-->
<!--                    v-if="selectSearchBy != 2 && selectSearchBy != 3"-->
<!--                    class="select-component mt-4"-->
<!--                    v-model="cinemaMultiselect"-->
<!--                    :value="cinemaMultiselect"-->
<!--                    @input=multiselectChangeCinema-->
<!--                    :options="cinemaList"-->
<!--                    :showLabel="true"-->
<!--                    :placeholder="$t('Cinema')"-->
<!--                    :error="errors.cinemas"-->
<!--                    :clearable="true"-->
<!--                />-->
<!--                <MultiselectComponent-->
<!--                    :value="sessionsValue"-->
<!--                    @change="multiselectChangeSessions"-->
<!--                    :options="sessionsList"-->
<!--                    :show-label="true"-->
<!--                    :placeholder="$t('Click on the box to select sessions')"-->
<!--                    :label="$t('Sessions')"-->
<!--                    name="sessions"-->
<!--                    class="mt-4"-->
<!--                    :multiselectClass="'multiselect&#45;&#45;mini'"-->
<!--                />-->
            </div>
            <div class="button-container" v-if="targetNotification">
                <a :href="targetNotification ? targetNotification.link : '#'">
                    <button
                        class="button-excel"
                        :class="{disabled: disabledBtn}"
                        type="button">{{$t('Download excel')}}
                    </button>
                </a>
            </div>
<!--            <TableReports :model="model" :params="this.params" :exportData="this.exportData"/>-->
            <div class="web-report" v-html="webData" v-if="webData"></div>
        </div>
    </div>
</template>

<script>
import SelectComponent from "../components/ui/forms/SelectComponent";
import Breads from "../components/Breads";
import PageHeader from "../components/PageHeader";
import DatePickerComponent from "../components/ui/forms/DatePickerComponent";
import MultiselectComponent from "../components/ui/forms/MultiselectComponent";
import InputComponent from "../components/ui/forms/InputComponent";
import TableReports from "../components/ui/tables/TableReports";
import axios from "axios";

export default {
    name: "Reports",
    components: {
        SelectComponent,
        Breads,
        PageHeader,
        DatePickerComponent,
        MultiselectComponent,
        InputComponent,
        TableReports
    },
    data() {
        return {
            reportOptions: [
                {id: 1, label: this.$t('One distributor report for one movie')},
                {id: 2, label: this.$t('Report of one cinema for one film')},
                {id: 3, label: this.$t('The result of one movie theater')},
            ],
            breadLinks: [
                {
                    label: this.$t('Reports'),
                    to: { name: 'Reports' }
                }
            ],
            reportData: {
                reportSelect: null,
                selectFilter: null,
                startDateSale: null,
                finishDateSale: null,
                startDateSession: null,
                finishDateSession: null,
            },
            mainFilterSelect: null,
            mainList: [],
            selectSearchBy: null,
            distributorsList: [],
            cinemaList: [],
            filmsList: [],
            errors: [],
            model: '',
            sessionsList: [],
            disabledBtn: true,
            networkList: [],
            targetNotification: null,
            sessionsValue: [],
            webLink: null,
            webData: null,
            sortBySale: {
                time: '',
            },
            sortBySession:{
                time: '',
            },
            filmMultiselect: [],
            distributorMultiselect: [],
            cinemaMultiselect: [],
            reportFilter: [
                {id: 1, label: this.$t('Network')},
                {id: 2, label: this.$t('Cinema')},
                {id: 3, label: this.$t('Distributor')},
            ],
            trapNotification: false,
            componentKey: 0,
            params: null,
            exportData: {},
        }
    },
    computed:{
        list() {
            return this.$store.getters.getNotificationsList
        },
    },
    watch: {
        list(){
            if (this.trapNotification){
                this.targetNotification = this.list[this.list.length - 1]
                if (this.list[this.list.length - 1].owner === 'export'){
                    this.disabledBtn = false
                    this.trapNotification = false
                }
            }
        },
        targetNotification(){
            if (this.targetNotification){
                this.webLink = this.targetNotification.web_link
                axios.get(this.webLink).then(resp => {
                    this.webData = resp.data
                })
            }
        },
        selectSearchBy(){
            this.clearSelect()
            this.updateAllLists()
            if (this.selectSearchBy === 1){
                this.mainList = this.distributorsList
                this.model = 'distributors'

            } else if (this.selectSearchBy === 2){
                this.mainList =  this.cinemaList
                this.model = 'cinema_movie'

            } else if (this.selectSearchBy === 3){
                this.mainList = this.cinemaList
                this.model = 'cinema_movie_stats'
            }
        },

        mainFilterSelect(){
            if (this.selectSearchBy === 1){
                this.multiselectChangeDistributors(this.mainFilterSelect)
            } else if (this.selectSearchBy === 2 || this.selectSearchBy === 3){
                this.multiselectChangeCinema(this.mainFilterSelect)
            }
        }
    },
    methods:{
        async getDistributorList(params){
            this.distributorsList = []
            let resp = await this.api.distributor.getDists(params)
            resp.data.forEach(dist => {
                this.distributorsList.push({
                    id: dist.id,
                    label: dist.name
                })
            })
        },
        multiselectChangeSessions(v) {
            this.sessionsValue = []

            v.map(elem => {
                this.sessionsValue.push(elem.id)
            })
        },
        async getSessions(params = null) {
            this.sessionsList = []
            let resp = await this.api.session.getSessions(params)
            if (resp?.length) {
                resp.map(item => {
                    let date = this.moment(item).format('YYYY-MM-DD');
                    this.sessionsList.push({
                        id: date,
                        label: date
                    });
                });
            }
        },
        clearButtonDate(by){
            if (by === 'session'){
                this.sortBySession.time = ''
                this.sortBySession.time = ''
            } else if (by === 'sale') {
                this.sortBySale.time = ''
                this.sortBySale.time = ''
            }
        },
        clearSelect(){
            this.reportData = {}
            this.sortBySale.time = ''
            this.sortBySession.time = ''
            this.cinemaMultiselect = []
            this.filmMultiselect = []
            this.distributorMultiselect = []
            this.cinemaMultiselect = []
            this.distributorsList = [],
            this.cinemaList = [],
            this.filmsList = [],
            // this.reportData.reportSelect = null
            // this.reportData.selectFilter = null
            this.mainFilterSelect = null
            this.componentKey +=1
        },
        getExportData(){
            this.exportData = {}
            if (this.filmsList.find(film => film.id === this.filmMultiselect)){
                this.exportData.filmName = this.filmsList.find(film => film.id === this.filmMultiselect).label
            }
            if (this.cinemaList.find(cinema => cinema.id === this.cinemaMultiselect)){
                this.exportData.cinemaName = this.cinemaList.find(cinema => cinema.id === this.cinemaMultiselect).label
            }
            this.exportData.reportData = this.reportData
        },
        async submitForm(){
            this.trapNotification = true
            let form = {}
            form.cinemas = [this.cinemaMultiselect]
            form.distributors = [this.distributorMultiselect]
            form.movies = [this.filmMultiselect]
            form.model = this.model
            form.date_from = this.reportData.startDateSale
            form.date_to = this.reportData.finishDateSale

            if (this.reportData.startDateSession){
                form.session_from = this.helper.getDate(this.reportData.startDateSession)
            }

            if(this.reportData.finishDateSession){
                form.session_to = this.helper.getDate(this.reportData.finishDateSession)
            }

            this.params = form

            this.getExportData()

            let resp = await this.api.export.sendExportData(form).catch(this.handleError);
            if (resp){
                this.toast.success(this.$t('The report was successfully generated'))
                this.errors = {}
                this.trapNotification = true
                // this.selectSearchBy = null
                // this.updateAllLists()
                // this.clearSelect()
            }
        },
        handleError(resp){
            this.errors = {}
            if (resp.statusCode === 422) {
                let err = {}
                for (const [key, value] of Object.entries(resp.messages)) {
                    let k = key.split(".")
                    let newKey = k[0]
                    err[newKey] = value
                }
                console.log(err)
                this.errors = err
            }
        },
        downloadExportExcel(){
            if (this.targetNotification){
                this.$router.push(this.targetNotification.link)
            }
        },
        sortDateSale(value){
            this.sortBySale.time = value
            let result = this.filterDate(value)
            this.reportData.startDateSale = result.startDate
            this.reportData.finishDateSale = result.finishDate
        },
        sortDateSession(value){
            this.sortBySession.time = value
            let result = this.filterDate(value)
            this.reportData.startDateSession = result.startDate
            this.reportData.finishDateSession = result.finishDate
        },
        filterDate(value){
            let startDate;
            let finishDate;
            if (value === 'yesterday'){
                startDate = this.helper.getYesterdayDate(new Date())
                finishDate = this.helper.getYesterdayDate(new Date())
            } else if (value === 'today'){
                startDate = this.helper.getDate(new Date())
                finishDate = this.helper.getDate(new Date())
            } else if (value === 'month'){
                startDate = this.helper.getCurrentMonth(new Date())
                finishDate = this.helper.getDate(new Date())
            } else if (value === 'previousMonth'){
                startDate = this.helper.getStartPreviousMonth(new Date())
                finishDate = this.helper.getEndPreviousMonth(new Date())
            } else if (value === 'nextMonth'){
                startDate = this.helper.getStartNextMonth(new Date())
                finishDate = this.helper.getFinishNextMonth(new Date())
            }

            return {
                startDate: startDate,
                finishDate: finishDate
            }

        },
        multiselectChangeDistributors(v) {
            this.distributorMultiselect = []

            // this.distributorMultiselect.push(v)
            this.distributorMultiselect = v
            this.getMovieList({no_pagination: 1, distributors_id: this.distributorMultiselect})
            this.getCinemaList({no_pagination: 1, distributors_id: this.distributorMultiselect})
            this.getNetworkList({no_pagination: 1, distributors_id: this.distributorMultiselect})
        },
        multiselectChangeCinema(v) {
            this.cinemaMultiselect = []

            if (typeof v === 'object' && v){
                v.map(elem => {
                    this.cinemaMultiselect.push(elem.id)
                })
            } else {
                this.cinemaMultiselect = v
            }

            this.getDistributorList({no_pagination: 1, cinemas_id: this.cinemaMultiselect})
            this.getMovieList({no_pagination: 1, cinemas_id: this.cinemaMultiselect})
            this.getNetworkList({no_pagination: 1, cinemas_id: this.cinemaMultiselect})
        },
        multiselectChangeMovie(v) {
            this.filmMultiselect = []
            if (typeof v === 'object' && v){
                v.map(elem => {
                    this.filmMultiselect.push(elem.id)
                })
            } else {
                // this.filmMultiselect.push(v)
                this.filmMultiselect = v
            }

            this.getDistributorList({no_pagination: true, movies_id: this.filmMultiselect})
            this.getCinemaList({no_pagination: true, movies_id: this.filmMultiselect})
            this.getNetworkList({no_pagination: true, movies_id: this.filmMultiselect})
        },
        async getMovieList(params){
            this.filmsList = []
            let resp = await this.api.cinema.getFilmsList(params)
            resp.data.forEach(movie => {
                this.filmsList.push({
                    id: movie.id,
                    label: movie.name
                })
            })
        },
        async getCinemaList(params){
            this.cinemaList = []
            let resp = await this.api.cinema.getCinemas(params)
            resp.data.forEach(cinema => {
                this.cinemaList.push({
                    id: cinema.id,
                    label: cinema.name
                })
            })
        },
        async getNetworkList(params){
            this.networkList = []
            let resp = await this.api.network.getNetworks(params)
            resp.data.forEach(network => {
                this.networkList.push({
                    id: network.id,
                    label: network.name
                })
            })
        },
        updateAllLists(){
            let noPagination = {no_pagination:1};

            this.getDistributorList(noPagination)
            this.getMovieList(noPagination)
            this.getCinemaList(noPagination)
            this.getNetworkList(noPagination)
        }
    },
    created() {
        // this.updateAllLists()
        this.getSessions()
    },
}
</script>
<style lang="scss">
.web-report{
    min-width: 100%;
    tbody, td, tfoot, th, thead, tr{
        border-width: 1px;
    }
    table{
        width: 100%;
    }
}

</style>
<style scoped lang="scss">
.head-container{
    padding: 0;
}
    .reports-container{
        background-color: white;
        padding: 25px 15px;
        margin-bottom: 30px;

        .head{
            display: flex;
            border-bottom: 1px solid $color_lightgray;
            border-radius: 2px;
            padding-bottom: 25px;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .head-select-container{
                display: flex;
                width: 82%;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
            .select-component{
                display: flex;
                align-items: center;
                width: 59.5%;
                margin-right: 3%;
            }
            .select-filter{
                width: 27%;
                position: relative;
                left: 3px;
            }

            .btn-generate-container{
                width: 18%;
                @media (max-width: 768px) {
                    width: 195px;
                    margin: 20px auto 0 auto;
                }

                .btn-generate{
                    width: 100%;
                    height: 45px;
                    border: none;
                    text-transform: uppercase;
                    border-radius: 2px;
                    background-color: $color_blue;
                    font-size: 12px;
                    font-weight: 900;
                    letter-spacing: 2px;
                    color: white;
                    &:hover{
                        color: $color_lightpink;
                        box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                    }
                }
            }
        }
        .date-container{
            border-bottom: 1px solid $color_lightgray;
            border-radius: 2px;
            padding-bottom: 40px;
        }
        .date-row{
            display: flex;
            margin-top: 40px;
            @media (max-width: 992px) {
                flex-direction: column;
            }
            .btn-date-container{
                width: 48%;
                display: flex;
                align-items: flex-end;
                position: relative;
                bottom: 6px;
                @media (max-width: 992px) {
                    position: static;
                    width: 100%;
                }
                @media (max-width: 576px) {
                    flex-direction: column;
                }
            }
            .btn-filter{
                width: 20%;
                height: 31px;
                border-radius: 2px;
                border: solid 1px $color_lightgray;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                letter-spacing: 0.35px;
                color: $color_gray;
                background-color: white;
                text-transform: lowercase;
                @media (max-width: 992px) {
                    width: 100%;
                }
                &:focus{
                    box-shadow: none;
                }

            }
            .active{
                font-size: 14px;
                letter-spacing: 0.35px;
                text-align: center;
                color: $color_black;
                background-color: $color_lightblue;
            }
            .date-picker{
                width: 22.8%;
                margin-right: 3%;
                @media (max-width: 992px) {
                    width: 100%;
                    margin-bottom: 20px;
                }
            }
        }
        .multiselect-container{
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding-bottom: 40px;
            @media (max-width: 576px) {
                flex-direction: column;
            }
            .border-bottom{
                border-bottom: 1px solid $color_lightgray;
                border-radius: 2px;
            }
            &:last-child{
                border: none;
            }

            .select-element{
                width: 50%;
                max-width: 625px;
                @media (max-width: 576px) {
                    width: 100%;
                    max-width: none;
                }
                &:first-child{
                    margin-right: 3%;
                }
            }
        }
        .button-container{
            display: flex;
            justify-content: center;
            .button-excel{
                text-transform: uppercase;
                height: 45px;
                width: 195px;
                border: none;
                border-radius: 2px;
                background-color: $color_blue;
                font-size: 12px;
                font-weight: 900;
                letter-spacing: 2px;
                color: white;
                &:hover{
                    color: $color_lightpink;
                    box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
                }
            }
            .disabled{
                cursor: auto;
                color: #fff;
                background-color: #646e78;
                &:hover{
                    box-shadow: none;
                    color: #fff;
                }
            }
        }

    }

    ::v-deep{
        .checked-select{
            .select-element__error{
                white-space: nowrap;
            }
        }
        .page-header{
            margin-top: 13px;
        }
        .select-element__label{
            margin-bottom: 0;
            padding-right: 19px;
        }
        .multiselect__tag-icon::after{
            //top: -5px;
            //left: -1px;
        }
        .form-group__error{
            top: 75px;
            bottom: auto;
        }
        .select-element__error{
            right: auto;
            left: 0;
        }
        .main-select{
            .select-element__error{
                right: auto;
                left: 0;
                bottom: -23px;
            }
        }

    }
</style>
