<template>
    <button
        v-if="!Object.keys(to || {}).length"
        class="btn button"
        :disabled="disabled"
        :class="[`button--${size}`, `button--${theme}`]"
        @click="$emit('click')"
    >
        <div class="preloader" v-if="preloader">
            <img src="@/assets/img/loader.svg" alt="svg" />
        </div>
        <i v-if="!customIcon && icon" :class="icon"></i>
        <img :src="customIcon" v-if="customIcon" />
        <span v-if="caption"> {{ caption }}</span>
    </button>
    <router-link
        v-else
        :to="to"
        :disabled="disabled"
        class="btn button"
        :class="[`button--${size}`, `button--${theme}`]"
    >
        <div class="preloader" v-if="preloader">
            <img src="@/assets/img/loader.svg" alt="svg" />
        </div>
        <i v-if="!customIcon && icon" :class="icon"></i>
        <img :src="customIcon" v-if="!icon && customIcon" />
        <span v-if="caption"> {{ caption }}</span>
    </router-link>
</template>

<script>
export default {
    name: "ButtonComponent",
    props: {
        caption: {
            required: false,
            type: String
        },
        customIcon: {
            required: false,
            type: String
        },
        icon: {
            required: false,
            type: String
        },
        preloader: {
            required: false,
            type: Boolean
        },
        size: {
            required: false,
            type: String,
            default: "large" // medium, large, small, flexible
        },
        to: {
            required: false,
            type: Object
        },
        theme: {
            required: false,
            type: String,
            default: "primary" // primary, bordered
        },
        disabled: {
            required: false,
            type: Boolean
        }
    },
};
</script>

<style lang="scss">
.button {
    border: none;
    border-radius: 2px;
    background-color: $color_blue;
    color: white;
    text-align: center;
    font-family: $ff-primary;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    height: max-content;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    font-weight: 900;

    &:hover {
        box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
        color: $color_lightblue;
    }

    &:focus {
        outline: none;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--primary {
        border: solid 1px $color_blue;
    }

    &--bordered, &--bordered-base {
        border: solid 1px #2d5ca6;
        background-color: white;
        color: $color_black;

        &:hover {
            box-shadow: 0 4px 10px 0 rgba(175, 175, 175, 0.25);
        }

        .preloader {
            img {
                filter: invert(1);
            }
        }
    }

    &--bordered-base {
        border: solid 1px #cad9f37a;
    }

    &--large {
        padding: 13px 15px;
        min-width: 170px;
    }

    &--flexible {
        padding: 13px 20px;
        min-width: auto;
    }

    &--medium {
        padding: 11px 10px;
        min-width: 85px;
        font-size: 13px;
        text-transform: capitalize;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: initial;
    }
}
</style>

<style lang="scss" scoped>
[disabled] {
    cursor: default;
    background-color: $color_gray;
    border-color: $color_gray;
    color: white;
}

.preloader {
    cursor: default;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 4px;
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 14px;
        animation: 3s linear 0s normal none infinite running i;
    }
    @keyframes i {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes i {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
