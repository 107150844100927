<template>
    <div class="table-container">
        <table v-if="model === 'distributors' && data">
            <thead>
            <tr>
                <th>Початок періоду</th>
                <th>Закінченя періоду</th>
                <th>Квитки/міс</th>
                <th>Виручка/міс</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in data.data">
               <td>{{helper.getDate(item.date)}}</td>
               <td>{{helper.getDate(helper.getStartNextMonth(item.date))}}</td>
               <td>{{item.count_sum}}</td>
               <td>{{item.amount_sum}}</td>
            </tr>
            </tbody>
        </table>


        <table v-if="model === 'cinema_movie' && data">
            <tr>
                <td colspan="4" style="text-align: center; vertical-align: middle" ><b>Звіт</b></td>
            </tr>
            <tr v-if="filmData">
                <td colspan="3" height="2">
                    про демонстрування кінофільму
                </td>
                <td colspan="2" height="2" style="text-align: center;">
                    <b>{{filmData.name}}</b>
                </td>
            </tr>
            <tr>
                <td colspan="3" height="2">
                    у кінотеатрі
                </td>
                <td colspan="2" height="2" style="text-align: center;">
                    <b>{{cinemaData.name}}</b>
                </td>
            </tr>
            <tr v-if="filmData">
                <td colspan="3" height="2">
                    Власник кінофільму
                </td>
                <td colspan="3" style="text-align: center;">
                  <b>{{filmData.production}}</b>
                </td>
            </tr>
            <tr>
                <td colspan="5" style="text-align: center;">
                    з {{helper.getDate(exportData.reportData.startDateSale)}} по {{helper.getDate(exportData.reportData.finishDateSale)}}
                </td>
            </tr>
            <tr>
                <td colspan="5" v-if="minPrice != 'Infinity'">
                    Ціна квитків: від {{minPrice}} грн.
                </td>
            </tr>
            <tr>
                <td style="text-align: center;"><b>Дата</b></td>
                <td style="text-align: center;"><b>Кількість сеансів</b></td>
                <td style="text-align: center;"><b>Кількість глядачів</b></td>
                <td style="text-align: center;" colspan="2"><b>Валовий збір (без ПДВ)</b></td>

            </tr>
          <tr v-for="item in data.data">
            <td style="text-align: center;">{{helper.getDate(item.date)}}</td>
            <td style="text-align: center;">{{item.movies_count}}</td>
            <td style="text-align: center;">{{item.count_sum}}</td>
            <td style="text-align: center;">{{item.amount_sum}}</td>
          </tr>
            <tr>
                <td><b>Разом</b></td>
                <td colspan="3" style="text-align: center;">{{data.tickets_amount}}</td>
            </tr>

        </table>


      <table v-if="model === 'cinema_movie_stats' && data">
        <tr>
          <td colspan="8"><b>1. Характеристика демонстратора фільмів</b></td>
        </tr>
        <tr>
          <td colspan="8"><b>1.1 Кінотеатр</b></td>
        </tr>
        <tr height="35">
          <td>N рядка</td>
          <td colspan="4">Кількість залів для демонстрування фільмів, одиниць</td>
          <td colspan="4">Кількість місць у залах, у яких здійснюється демонстрування фільмів одиниць</td>
        </tr>
        <tr>
          <td>А</td>
          <td colspan="4">1</td>
          <td colspan="4">2</td>
        </tr>
        <tr>
          <td>01</td>
          <td colspan="4">{{data.halls_count}}</td>
          <td colspan="4">{{data.count_seats}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td colspan="8"><b>3. Основні показники роботи демонстратора фільмів</b></td>
        </tr>
        <tr>
          <td colspan="2">Найменування показника</td>
          <td colspan="3">N рядка</td>
          <td colspan="3">усього</td>
        </tr>
        <tr height="35">
          <td colspan="2">Кількість сеансів</td>
          <td colspan="3">01</td>
          <td colspan="3">{{data.halls_count}}</td>
        </tr>
        <tr height="35">
          <td colspan="2">Кількість глядачів, чол.</td>
          <td colspan="3">02</td>
          <td colspan="3">{{data.tickets_count}}</td>
        </tr>
        <tr></tr>
        <tr>
          <td colspan="8"><b>4. Показ кіно (відео) фільмів за період</b></td>
        </tr>
        <tr height="60">
          <td rowspan="2" colspan="2">Кінофільми</td>
          <td rowspan="2">N рядка</td>
          <td colspan="7">Фільми які демонструвалися</td>
        </tr>
        <tr>
          <td >Кількість назв</td>
          <td colspan="3">Кількість глядачів осіб</td>
          <td colspan="3">Дохід від реалізації квитків тис. грн.</td>
        </tr>
        <tr>
          <td colspan="2">А</td>
          <td>Б</td>
          <td>1</td>
          <td colspan="3">2</td>
          <td colspan="3">3</td>
        </tr>
        <tr height="30">
          <td colspan="2">Українського виробництва</td>
          <td>01</td>
          <td>{{ukraine ? 1 : 0}}</td>
          <td colspan="3">{{ukraine ? data.tickets_count : 0}}</td>
          <td colspan="3">{{ukraine ? data.tickets_amount/1000 : 0}}</td>
        </tr>
        <tr height="30">
          <td colspan="2">Іноземного виробництва усього</td>
          <td>02</td>
          <td>{{foreign ? 1 : 0}}</td>
          <td colspan="3">{{foreign ? data.tickets_count : 0}}</td>
          <td colspan="3">{{foreign ? data.tickets_amount/1000 : 0}}</td>
        </tr>
        <tr>
          <td colspan="2">Усього</td>
          <td>10</td>
          <td>{{foreign + ukraine}}</td>
          <td colspan="3">{{data.tickets_count}}</td>
          <td colspan="3">{{data.tickets_amount/1000}}</td>
        </tr>
      </table>

    </div>
</template>

<script>
export default {
    name: "TableReports",
    data (){
        return{
            data: null,
            filmData: null,
            minPrice: null,
            cinemaData: null,
            foreign: 0,
            ukraine: 0,
        }
    },
    props:{
        model:{
            type: String,
            required: true,
        },
        params:{
            type: Object,
            required: false,
        },
        exportData:{
            type: Object,
            required: false,
        }
    },
    watch:{
      params(){
          if (this.model === 'distributors'){
              if(!this.params.cinemas[0].length){
                  this.params.cinemas = null
              } else {
                  this.getCinemasId(this.params.cinemas[0])
              }
          }
          this.getAnalytics(this.params)
          this.getFilmsId(this.params.movies[0])
          if (this.model === 'cinema_movie_stats'){
              this.getMovieListById()
          }
          if (this.model === 'cinema_movie') {
              this.getCinemasId(this.params.cinemas[0])

          }
      },
    },
    methods:{
        async getAnalytics(params){
            this.params.format = 'month'
            if (!this.params.distributors[0].length) {
                this.params.distributors = []
            }
            if (!this.params.movies[0].length) {
                this.params.movies = []
            }
            let resp = await this.api.ticket.getAnalytics(params)
            this.data = resp
            this.getMinPrice()
        },
        async getMovieListById(){
            let resp = await this.api.cinema.getFilmsList({no_pagination: true, id: this.params.movies[0]})
            resp.data.forEach(film => {
              if (film.production_type === "foreign"){
                this.foreign = 1
              } else {
                this.ukraine = 1
              }
            })
        },
        async getFilmsId(id){
          let resp = await this.api.cinema.getFilmsId(id)
          this.filmData = resp.data
        },
        async getCinemasId(id){
          let resp = await this.api.cinema.getCinemaId(id)
          this.cinemaData = resp.data
        },
        getMinPrice(){
          let priceList = []
          this.data.data.forEach(el => {
            priceList.push(el.amount_sum)
          })
          this.minPrice = Math.min.apply(null, priceList)
        }

    }
}
</script>

<style scoped lang="scss">
.table-container{
  margin-top: 40px;
    table{
        width: 100%;
        th, td{
            border: 1px solid black;
        }
    }
}
</style>
